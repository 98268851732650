@import '@hh.ru/magritte-ui/tokens';
@import '@hh.ru/magritte-ui/breakpoints';
@import '/src/styles/common';

.header {
    width: 100%;
    height: @header-height-mobile;
    padding: @magritte-core-space-1-x @magritte-core-space-4-x;
    margin-bottom: @magritte-core-space-4-x;
    display: flex;
    align-items: center;
    gap: @magritte-core-space-3-x;
    box-sizing: border-box;

    .screen-gt-s({
        height: @header-height-desktop;
        padding: @magritte-core-space-6-x;
        margin-bottom: 0;
        gap: @magritte-core-space-6-x;
    });
}

.header_stick-right {
    padding-right: 0;

    .screen-gt-s({
        padding-right: @magritte-core-space-4-x;
    });
}

.header_absolute {
    position: absolute;
    top: 0;
    width: 100%;
}

.header-controls {
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: @magritte-core-space-4-x;
}

.logo_center {
    display: flex;
    flex-grow: 1;
    justify-content: center;
}
