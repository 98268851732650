@import '@hh.ru/magritte-ui/tokens';
@import '@hh.ru/magritte-ui/breakpoints';
@import '/src/styles/common';

.container {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.title {
    // обязательно оставляем padding что-бы hover срабатывал по всему пространству
    padding-left: @magritte-core-space-2-x;
    padding-top: @magritte-core-space-1-x;

    // TODO: нужно поправить на новый цвет из темы созданный специально для лого,
    color: @magritte-color-text-primary;
    width: 71px;
    height: 22px;

    svg {
        width: inherit;
        height: inherit;
        fill: currentColor;
    }

    .screen-gt-s({
        padding-left: 10px;
        width: 86px;
        height: 26px;
    });
}

.hh-logo {
    background-image: url('./assets/logo.svg');
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 100%;
    height: @logo-size-mobile;
    width: @logo-size-mobile;

    .screen-gt-s({
        height: @logo-size;
        width: @logo-size;
    });
}
