@import '/src/styles/custom-header-link';
@import '@hh.ru/magritte-ui/tokens';

.button {
    .custom-header-link();

    display: flex;
    align-items: center;
    gap: @magritte-core-space-1-x;
    flex-wrap: nowrap;
}
