@import '@hh.ru/magritte-ui/tokens';

.theme-container_background-body {
    background-color: @magritte-color-background-body;
}

.theme-container_background-secondary {
    background-color: @magritte-color-background-secondary;
}
.theme-container_background-tertiary {
    background-color: @magritte-color-background-tertiary;
}

.theme-container_background-transparent {
    background-color: transparent;
}
