@import '@hh.ru/magritte-ui/reset';
@import '/src/styles/font';

html,
body {
    overscroll-behavior: auto;
}

:global(.uxfeedback-widget) {
    display: none;
    width: 0;
    height: 0;
}
